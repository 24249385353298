import styled from "styled-components";
import { Container, SectionTitle } from "../styles/globalStyles";
import { motion } from "framer-motion";

const data = [
  {
    id: 1,
    company: "Accenture Solutions Pvt Ltd",
    role: "Associate Software Engineer",
    fromDate: new Date("2021-05-13"),
    toDate: new Date("2022-05-31"),
    tasks: [
      "Collaborated with multidisciplinary teams, including designers, frontend developers, and content creators, to ensure seamless integration of frontend designs and AEM components",
      "Participated in code reviews and provided constructive feedback to enhance code quality and maintainability",
      "Designed APIs for interacting with CMS tools and executed end-to-end flow of builds",
    ],
  },
  {
    id: 2,
    company: "Accenture Solutions Pvt Ltd",
    role: "Software Engineer",
    fromDate: new Date("2022-06-01"),
    toDate: new Date("2022-08-02"),
    tasks: [
      "Restructured the mobile app using Config driven UI, JSON based rendering and GraphQL as middleware, resulting in a 40% decrease in UI development time and improved scalability",
      "Designed reusable accordions with dynamic dropdowns that are only loaded when accessed improving the efficiency and speed of page rendering by dynamic loading of images",
      "Participated in code reviews and provided constructive feedback to enhance code quality and maintainability",
    ],
  },
  {
    id: 3,
    company: "Vensoft Inc",
    role: "Software Developer Intern",
    fromDate: new Date("2024-01-01"),
    toDate: new Date("2024-04-25"),
    tasks: [
      "Built a retail seller application",
      "Worked extensively on React.js and TailwindCSS",
      "Built User Interfaces, fixed bugs, and integrated APIs",
      "Collaborated with backend and UI Developers",
    ],
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Experience = () => {
  const monthDiff = (dateFrom, dateTo) => {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  };

  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, y: 300 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <SectionTitle>Work Experience</SectionTitle>

        <TimeLine>
          {data
            .sort((a, b) => b.fromDate.getTime() - a.fromDate.getTime())
            .map((ex) => (
              <TimeLineItem key={ex.id}>
                <ExperieceHeader>
                  <h3>
                    {ex.role}, {ex.company}
                  </h3>

                  <small>
                    {months[ex.fromDate.getMonth()] +
                      " " +
                      ex.fromDate.getFullYear()}{" "}
                    -{" "}
                    {ex.toDate.toDateString() === new Date().toDateString() ? (
                      "Present "
                    ) : (
                      <span>
                        {months[ex.toDate.getMonth()] +
                          " " +
                          ex.toDate.getFullYear()}{" "}
                      </span>
                    )}
                    ({monthDiff(ex.fromDate, ex.toDate)}{" "}
                    {monthDiff(ex.fromDate, ex.toDate) > 1 ? "Months" : "Month"}
                    )
                  </small>
                </ExperieceHeader>

                <TaskList>
                  {ex.tasks.map((task, i) => (
                    <li key={i}>{task}</li>
                  ))}
                </TaskList>
              </TimeLineItem>
            ))}
        </TimeLine>
      </motion.div>
    </Container>
  );
};

export default Experience;

const TimeLine = styled.ul`
  list-style-type: none;
  margin: 1em 0;
  padding: 1em;
`;

const TimeLineItem = styled.li`
  padding: 0 0 2em 2em;
  border-left: 1px solid var(--accent-blue);
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -6px;
    padding: 4px;
    border-radius: 50%;
    background-color: var(--accent-blue);
    border: 2px solid var(--accent-blue);
  }

  p {
    opacity: 0.7;
  }

  ul {
    padding: 0 1em;
  }
`;

const ExperieceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  top: -10px;

  h3 {
    font-weight: 400;
  }

  small {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const TaskList = styled.ul`
  list-style-type: circle;

  li {
    color: var(--para-gray-color);
  }
`;
