import { nanoid } from 'nanoid';

const projects = [

  {
    id: nanoid(),
    title: 'Healthcare Portal',
    img: '/imgs/projects/healthCarePortal.webp',
    desc: "Developed a comprehensive web application for managing hospital operations",
    technologies: ['React.js', 'TailwindCSS', 'Redux'],
    github: 'https://github.com/manasa-kalluru',
    live: 'https://sridevichandra.in/',
  },
  {
    id: nanoid(),
    title: 'Netflix-GPT',
    img: '/imgs/projects/netflixClone.png',
    desc: 'A fully responsive web application designed to replicate the core functionalities of Netflix',
    technologies: ['React.js', 'TypeScript', 'MantineUI'],
    github: 'https://github.com/manasa-kalluru/',
    live: 'https://netflix-gpt-3ae3b.web.app/',
  },

  {
    id: nanoid(),
    title: 'Medicare Hub',
    img: '/imgs/projects/hospitalManagement.png',
    desc: 'A user interface designed to digitize the administrative processes of a hospital',
    technologies: ['React.js', 'TypeScript', 'TailwindCSS'],
    github: 'https://github.com/manasa-kalluru/',
    live: 'http://hospital-management-new.s3-website.ap-south-1.amazonaws.com/dashboard',
  },
 
  {
    id: nanoid(),
    title: 'FoodZilla',
    img: '/imgs/projects/foodzilla.png',
    desc: "A food delivery App built using Reactjs and Powered by Swiggy's API",
    technologies: ['React.js', 'TailwindCSS', 'Redux'],
    github: 'https://github.com/manasa-kalluru/react_foodApp',
    live: 'https://foodzilla-a4e34.web.app/',
  },
  
 
 
];

const skills = [
  {
    icon: 'html',
    title: 'HTML',
  },
  {
    icon: 'css',
    title: 'CSS',
  },
  {
    icon: 'javascript',
    title: 'JavaScript',
  },
  {
    icon: 'typescript',
    title: 'TypeScript',
  },
  {
    icon: 'react',
    title: 'React.js',
  },
  {
    icon: 'redux',
    title: 'Redux',
  },
  {
    icon: 'tailwindcss',
    title: 'TailwindCSS',
  },
  // {
  //   icon: 'bootstrap',
  //   title: 'Bootstrap',
  // },
  {
    icon: 'firebase',
    title: 'Firebase',
  },
  {
    icon: 'nodejs',
    title: 'Node.js',
  },
  {
    icon: 'expressjs',
    title: 'Express.js',
  },
  {
    icon: 'mongodb',
    title: 'MongoDB',
  },
  {
    icon: 'sass',
    title: 'Sass',
  },
  {
    icon: 'git',
    title: 'Git',
  },
  // {
  //   icon: 'figma',
  //   title: 'Figma',
  // },
  {
    icon: 'github',
    title: 'Github',
  },
  {
    icon: 'babel',
    title: 'Babel',
  },
  {
    icon: 'webpack',
    title: 'Webpack',
  },
];

export const data = { projects, skills };
